import type { NextPage } from "next";
import { useEffect, useState } from "react";
import { Contracts } from "./api/contracts";
const PASSWORD = process.env.NEXT_PUBLIC_PASSWORD || "";

const Home: NextPage = () => {
  const [contracts, setContracts] = useState<Contracts>({});
  useEffect(() => {
    fetch("/api/contracts", {
      headers: {
        Authorization: PASSWORD,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setContracts(data);
      });
  }, []);

  return (
    <div className="p-5">
      <div className="flex items-top">
        <div className="shrink">
          <h3 className="text-3xl">How to update</h3>
          <div className="divider"></div>
          <p className="text-xl">
            Send a POST request to the {window.location.href}api/contracts
            endpoint with a JSON payload.
          </p>
          <p className="mt-8">Required attributes:</p>
          <ul className="list-disc list-inside">
            <li className="list-item">
              env <em>[working environment]</em>
            </li>
            <li className="list-item">
              name <em>[contract name]</em>
            </li>
            <li className="list-item">
              addr <em>[contract address]</em>
            </li>
          </ul>

          <p className="mt-8">Example payload:</p>
          <div className="bg-neutral text-info p-3 rounded-lg">
            <pre>
              {JSON.stringify(
                {
                  env: "dev",
                  name: "price_oracle",
                  addr: "noria1l3fq298fx46flzkvam02wwtpyrcu7kyu7xuqjjuvxvanx77n2jvslgqtg3",
                },
                null,
                2
              )}
            </pre>
          </div>

          {PASSWORD.length > 0 && (
            <p className="mt-8">
              The request must be authenticated with an{" "}
              <strong>Authorization</strong> header using the password{" "}
              <strong>{PASSWORD}</strong>.
            </p>
          )}

          <p className="mt-8">Example cURL command:</p>
          <div className="bg-neutral text-info p-3 rounded-lg">
            <div> curl -X POST \</div>
            <div>-H &quot;Content-Type: application/json&quot; \</div>
            {PASSWORD.length > 0 && (
              <div>-H &quot;Authorization: {PASSWORD}&quot; \</div>
            )}
            <div>
              -d &apos;
              {JSON.stringify({
                env: "dev",
                name: "price_oracle",
                addr: "noria1l3fq298fx46flzkvam02wwtpyrcu7kyu7xuqjjuvxvanx77n2jvslgqtg3",
              })}
              &apos; \
            </div>
            {window.location.href}api/contracts
          </div>
        </div>
        <div className="divider divider-horizontal"></div>
        <div className="flex-1">
          <h3 className="text-3xl">Contracts</h3>
          <div className="divider"></div>
          <div className="bg-neutral text-primary p-3 rounded-lg">
            <pre>{JSON.stringify(contracts, null, 2)}</pre>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
